import React from 'react'
import { Link, navigate, graphql } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/Title'

class FormSuccessPage extends React.Component {
  componentDidMount() {
    // prevent running during build
    if (window.location.search.includes('lang=en')) {
      navigate('/en/form/')
    }
  }

  render() {
    const {
      pageContext: { locale },
      data: {
        file: {
          childFormSuccess: { title, subtitle, button },
        },
      },
    } = this.props

    return (
      <Layout locale={locale}>
        <section className="section hero is-fullheight-with-navbar has-background-white">
          <div className="container">
            <Title title={title} subtitle={subtitle}>
              <Link
                to={locale === 'fr' ? '/' : '/en/'}
                className="button is-primary"
              >
                {button}
              </Link>
            </Title>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query($locale: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: "formSuccess" }) {
      childFormSuccess {
        title
        subtitle
        button
      }
    }
  }
`

export default FormSuccessPage
